 
import { ActionType, createAction, props } from "@ngrx/store";
import {
  IDocumentFieldVersionListLookUp,
  IDocumentType,
  IDocumentVersionField,
  ILOSField,
  IOption,
  IProject,
  IRule,
  IRuleFilters,
  IRuleWithId,
} from "./rule-engine.types";

export const ADD_RULE = "ADD_RULE";
export const UPDATE_RULE = "UPDATE_RULE";
export const GET_RULE_BY_ID = "GET_RULE_BY_ID";
export const GET_ADDED_RULE_SUCCESS = "GET_ADDED_RULE_SUCCESS";
export const GET_ADDED_RULE_FAILURE = "GET_ADDED_RULE_FAILURE";
export const GET_UPDATED_RULE_SUCCESS = "GET_UPDATED_RULE_SUCCESS";
export const GET_RULE_BY_ID_SUCCESS = "GET_RULE_BY_ID_SUCCESS";
export const CLEAR_RULE_STATE = "CLEAR_RULE_STATE";
export const UPDATE_DOCUMENT_TYPES = "UPDATE_DOCUMENT_TYPES";
export const UPDATE_DOCUMENT_FIELD_VERSION_LIST_LOOKUP = "UPDATE_DOCUMENT_FIELD_VERSION_LIST_LOOKUP";

export const GET_LOS_FIELD_LIST = "GET_LOS_FIELD_LIST";
export const GET_LOS_FIELD_LIST_SUCCESS = "GET_LOS_FIELD_LIST_SUCCESS";
export const GET_LOS_FIELD_LIST_FAILURE = "GET_LOS_FIELD_LIST_FAILURE";

export const UPDATE_RULE_FILTERS = "UPDATE_RULE_FILTERS";

export const GET_PROJECTS = "GET_PROJECTS";
export const GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS";
export const GET_PROJECTS_FAILURE = "GET_PROJECTS_FAILURE";

export const GET_RULES = "GET_RULES";
export const GET_RULES_SUCCESS = "GET_RULES_SUCCESS";
export const GET_RULES_FAILURE = "GET_RULES_FAILURE";

export const GET_DOCUMENT_TYPES = "GET_DOCUMENT_TYPES";
export const GET_DOCUMENT_TYPES_SUCCESS = "GET_DOCUMENT_TYPES_SUCCESS";
export const GET_DOCUMENT_TYPES_FAILURE = "GET_DOCUMENT_TYPES_FAILURE";

export const GET_APPLICABLE_LOAN_TYPES = "GET_APPLICABLE_LOAN_TYPES";
export const GET_APPLICABLE_LOAN_TYPES_SUCCESS = "GET_APPLICABLE_LOAN_TYPES_SUCCESS";
export const GET_APPLICABLE_LOAN_TYPES_FAILURE = "GET_APPLICABLE_LOAN_TYPES_FAILURE";

export const GET_DOCUMENT_VERSION_FIELD_LIST = "GET_DOCUMENT_VERSION_FIELD_LIST";
export const GET_DOCUMENT_VERSION_FIELD_LIST_SUCCESS = "GET_DOCUMENT_VERSION_FIELD_LIST_SUCCESS";
export const GET_DOCUMENT_VERSION_FIELD_LIST_FAILURE = "GET_DOCUMENT_VERSION_FIELD_LIST_FAILURE";

export const CLEAR_DOCUMENT_TYPE_ERROR_STATE = "CLEAR_DOCUMENT_TYPE_ERROR_STATE";

export const AddRule = createAction(ADD_RULE, props<{ rule: IRule }>());

export const GetAddedRuleSuccess = createAction(GET_ADDED_RULE_SUCCESS, props<{ rule: IRuleWithId }>());
export const GetAddedRuleFailure = createAction(GET_ADDED_RULE_FAILURE, props<{ rule: undefined, errorMessage: string }>());

export const UpdateRule = createAction(UPDATE_RULE, props<{ ruleId: string; rule: IRule }>());

export const GetUpdatedRuleSuccess = createAction(GET_UPDATED_RULE_SUCCESS, props<{ rule: IRuleWithId }>());

export const GetRuleById = createAction(GET_RULE_BY_ID, props<{ ruleId: string , projectId: number }>());

export const GetRuleByIdSuccess = createAction(GET_RULE_BY_ID_SUCCESS, props<{ rules: IRuleWithId[] }>());

export const ClearRuleState = createAction(CLEAR_RULE_STATE);

export const UpdateDocumentTypes = createAction(UPDATE_DOCUMENT_TYPES, props<{ documentTypes: IOption<string>[] }>());

export const UpdateDocumentFieldVersionListLookUp = createAction(
  UPDATE_DOCUMENT_FIELD_VERSION_LIST_LOOKUP,
  props<{ documentFieldVersionListLookUp: IDocumentFieldVersionListLookUp }>()
);

export const getLOSFieldList = createAction(GET_LOS_FIELD_LIST, props<{ projectId: number }>());
export const getLOSFieldListSuccess = createAction(GET_LOS_FIELD_LIST_SUCCESS, props<{ LOSFieldList: ILOSField[] }>());
export const getLOSFieldListFailure = createAction(GET_LOS_FIELD_LIST_FAILURE, props<{ LOSFieldList: ILOSField[] }>());

export const updateRuleFilters = createAction(UPDATE_RULE_FILTERS, props<{ ruleFilters: IRuleFilters | undefined }>());

export const GetProjectsByCustomerId = createAction(GET_PROJECTS, props<{ customerId: number }>());

export const GetProjectsByCustomerIdSuccess = createAction(
  GET_PROJECTS_SUCCESS,
  props<{ projects: IProject[] }>()
);

export const GetProjectsByCustomerIdFailure = createAction(
  GET_PROJECTS_FAILURE,
  props<{ projects: IProject[] }>()
);

export const GetRulesList = createAction(GET_RULES, props<{ ruleFilters: IRuleFilters, isFreshFetch: boolean }>());

export const GetRulesListSuccess = createAction(
  GET_RULES_SUCCESS,
  props<{ rulesList: IRuleWithId[], isFreshFetch: boolean }>()
);

export const GetRulesListFailure = createAction(
  GET_RULES_FAILURE,
  props<{ rulesList: IRuleWithId[], isFreshFetch: boolean }>()
);

export const GetDocumentTypes = createAction(GET_DOCUMENT_TYPES, props<{ projectId: number, documentType: string }>());

export const GetDocumentTypesSuccess = createAction(
  GET_DOCUMENT_TYPES_SUCCESS,
  props<{ documentTypes: IDocumentType[] }>()
);

export const GetDocumentTypesFailure = createAction(
  GET_DOCUMENT_TYPES_FAILURE,
  props<{ documentTypes: IDocumentType[] }>()
);

export const GetApplicableLoanTypes = createAction(GET_APPLICABLE_LOAN_TYPES, props<{ projectId: number, documentType: string }>());

export const GetApplicableLoanTypesSuccess = createAction(
  GET_APPLICABLE_LOAN_TYPES_SUCCESS,
  props<{ applicableLoanTypes: IDocumentType[] }>()
);

export const GetApplicableLoanTypesFailure = createAction(
  GET_APPLICABLE_LOAN_TYPES_FAILURE,
  props<{ applicableLoanTypes: IDocumentType[] }>()
);

export const GetDocumentVersionFieldList = createAction(GET_DOCUMENT_VERSION_FIELD_LIST, props<{ documentCode: string, projectId: number }>());

export const GetDocumentVersionFieldListSuccess = createAction(
  GET_DOCUMENT_VERSION_FIELD_LIST_SUCCESS,
  props<{ documentVersionFieldList: IDocumentVersionField[] }>()
);

export const GetDocumentVersionFieldListFailure = createAction(
  GET_DOCUMENT_VERSION_FIELD_LIST_FAILURE,
  props<{ documentVersionFieldList: IDocumentVersionField[] }>()
);

export const ClearDocumentTypeErrorState = createAction(CLEAR_DOCUMENT_TYPE_ERROR_STATE);

export type RuleEngineActions =
  | ActionType<typeof AddRule>
  | ActionType<typeof UpdateRule>
  | ActionType<typeof GetRuleById>
  | ActionType<typeof GetAddedRuleSuccess>
  | ActionType<typeof GetUpdatedRuleSuccess>
  | ActionType<typeof GetRuleByIdSuccess>
  | ActionType<typeof ClearRuleState>
  | ActionType<typeof UpdateDocumentTypes>
  | ActionType<typeof UpdateDocumentFieldVersionListLookUp>
  | ActionType<typeof getLOSFieldList>
  | ActionType<typeof getLOSFieldListSuccess>
  | ActionType<typeof getLOSFieldListFailure>
  | ActionType<typeof updateRuleFilters>
  | ActionType<typeof GetAddedRuleFailure>
  | ActionType<typeof GetRulesList>
  | ActionType<typeof GetRulesListSuccess>
  | ActionType<typeof GetRulesListFailure>
  | ActionType<typeof GetDocumentTypes>
  | ActionType<typeof GetDocumentTypesSuccess>
  | ActionType<typeof GetDocumentTypesFailure>
  | ActionType<typeof GetApplicableLoanTypes>
  | ActionType<typeof GetApplicableLoanTypesSuccess>
  | ActionType<typeof GetApplicableLoanTypesFailure>
  | ActionType<typeof GetProjectsByCustomerId>
  | ActionType<typeof GetProjectsByCustomerIdSuccess>
  | ActionType<typeof GetProjectsByCustomerIdFailure>
  | ActionType<typeof ClearDocumentTypeErrorState>
  | ActionType<typeof GetDocumentVersionFieldList>
  | ActionType<typeof GetDocumentVersionFieldListSuccess>
  | ActionType<typeof GetDocumentVersionFieldListFailure>;
