// income-calculation.service.ts
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import moment from "moment";
import { Observable, tap } from "rxjs";
import { ApiResponseModel } from "src/app/models/common.model";
import { CalculateIncomeBody } from "src/app/models/loans";
import {
  BankStatementStatusUpdateRequest,
  LargeDepositDto,
  TransactionBucketUpdateDTO,
} from "src/app/models/loanThresholdRequest";
import {
  AccountSummaryDto,
  IncomeDetailsDto,
  TransactionCategoriesDto,
} from "src/app/store/incomeCalculation/incomeCalculation.types";
import { APIResponse } from "src/app/store/rule-engine/rule-engine.types";
import { TokenStorageService } from "../TokenStorageService/token-storage.service";

@Injectable({
  providedIn: "root",
})
export class IncomeCalculationServiceService {
  idToken: string;
  constructor(
    private http: HttpClient,
    private tokenStorageService: TokenStorageService,
    @Inject("BASE_URL") private baseUrl: string
  ) {
    this.idToken = tokenStorageService.getAccessToken() || "";
  }
  apiUrl = `${this.baseUrl}api/StatementTransaction/`;

  getAllIncomeDetails(loanId: string): Observable<APIResponse<IncomeDetailsDto>> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    return this.http.get<APIResponse<IncomeDetailsDto>>(
      `${this.baseUrl}api/StatementTransaction/IncomeCalculationsDetails/${loanId}?projectId=${this.tokenStorageService.getCurrentProjectIdForBSIC()}`,
      { headers }
    );
  }
  CalculateIncome(calculateIncomeBody: CalculateIncomeBody): Observable<APIResponse<boolean>> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    return this.http.post<APIResponse<boolean>>(
      `${this.baseUrl}api/Loan/IncomeCalculation?projectId=${this.tokenStorageService.getCurrentProjectIdForBSIC()}`,
      calculateIncomeBody,
      {
        headers,
      }
    );
  }
  getTransactionCategories(loanId: string): Observable<APIResponse<TransactionCategoriesDto[]>> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    return this.http.get<APIResponse<TransactionCategoriesDto[]>>(
      `${this.baseUrl}api/StatementTransaction/TransactionCategories/${loanId}?projectId=${this.tokenStorageService.getCurrentProjectIdForBSIC()}`,
      { headers }
    );
  }

  updateStatementTransactionBucket(
    loanId: string,
    IsQualified: boolean,
    categoryCode: string
  ): Observable<APIResponse<string>> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    const url = `${this.apiUrl}StatementTransactionCategory?projectId=${this.tokenStorageService.getCurrentProjectIdForBSIC()}`;
    const statement: TransactionBucketUpdateDTO = {
      loanId: loanId,
      IsQualified: IsQualified,
      categoryCode: categoryCode,
    };
    return this.http.put<APIResponse<string>>(url, statement, { headers });
  }

  getDistinctStatementSummary(loanId: string): Observable<ApiResponseModel<AccountSummaryDto[]>> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
      "Content-Type": "application/json",
    });
    return this.http.get<ApiResponseModel<AccountSummaryDto[]>>(
      `${this.baseUrl}api/StatementSummary/GetDistinctStatementSummaryByLoanId?projectId=${this.tokenStorageService.getCurrentProjectIdForBSIC()}&loanId=${loanId}`,
      { headers }
    );
  }

  saveAccountStatus(accountDetails: AccountSummaryDto[]): Observable<APIResponse<string>> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    const url = `${this.baseUrl}api/StatementSummary/saveAccountStatus?projectId=${this.tokenStorageService.getCurrentProjectIdForBSIC()}`;
    return this.http.post<APIResponse<string>>(url, accountDetails, { headers });
  }

  updateStatementByAccountNumber(accountNum: string, isAccountSelected: boolean): Observable<APIResponse<string>> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    const url = `${this.baseUrl}api/StatementSummary/StatementByAccountNumber?projectId=${this.tokenStorageService.getCurrentProjectIdForBSIC()}`;
    const statement: BankStatementStatusUpdateRequest = { LoanId: accountNum, isAccountSelected: isAccountSelected };
    return this.http.put<APIResponse<string>>(url, statement, { headers });
  }

  updateThreshold(loanID: string, thresholdFactor: number): Observable<APIResponse<string>> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    const url = `${this.baseUrl}api/StatementTransaction/LargeDeposit?projectId=${this.tokenStorageService.getCurrentProjectIdForBSIC()}`;
    // Ensure the timerValue is included in the account object
    const thresholdRequest: LargeDepositDto = { LoanId: loanID, ThresholdValue: thresholdFactor };
    return this.http.put<APIResponse<string>>(url, thresholdRequest, { headers });
  }

  exportToExcel(loanId: string, loanNumber: string): Observable<Blob> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });

    return this.http
      .get(
        `${this.baseUrl}api/Loan/Export/${loanId}?projectId=${this.tokenStorageService.getCurrentProjectIdForBSIC()}`,
        {
          headers,
          responseType: "blob",
        }
      )
      .pipe(
        tap((response) => {
          const now = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
          const url = window.URL.createObjectURL(response);
          const link = document.createElement("a");
          link.href = url;
          link.download = `BankStatement_${loanNumber}_${now}.xlsx`;
          link.target = "_blank";
          link.click();
          window.URL.revokeObjectURL(url);
        })
      );
  }
}
